import React, {useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import {
    faChevronDown,
    faChevronUp
} from '@fortawesome/free-solid-svg-icons'
import * as styles from './footer.module.scss';
import { Link } from "gatsby";
import Logo from '../../../svg/masterwizr-logo-footer.svg';

const Footer = () => {
    const [isActiveModules, setActiveModules] = useState(false);
    const [isActiveAbout, setActiveAbout] = useState(false);
    const showModules = () => {
        setActiveModules(!isActiveModules);
    }
    const showAbout = () => {
        setActiveAbout(!isActiveAbout);
    }

    return(
        <div className={styles.footer}>
            <div className={styles.container}>
                <div className={styles.upper}>
                    <div className={styles.description}>
                        <Logo className={styles.logo}/>
                        <p>MASTER WiZR the world’s premier presentation and meeting tool. Driven by our mission to achieve excellence we empower individuals and companies of all sizes.</p>
                        <p className={styles.meet_us}>Meet us at:
                          <a aria-label="redirect" href="https://www.facebook.com/masterwizr" target="_blank" rel="noreferrer">  <FontAwesomeIcon icon={faFacebookF} style={{paddingLeft: 10, height:15, width: 15}}/> </a>
                           <a aria-label="redirect" href="https://www.instagram.com/masterwizr/?hl=en" target="_blank" rel="noreferrer"> <FontAwesomeIcon icon={faInstagram} style={{paddingLeft: 10, height:15, width: 18}}/> </a>
                           <a aria-label="redirect" href="https://no.linkedin.com/company/masterwizr" target="_blank" rel="noreferrer"> <FontAwesomeIcon icon={faLinkedinIn} style={{paddingLeft: 10, height:15, width: 18}}/> </a>
                        </p>
                    </div>
                    <div className={styles.links}>
                        <h3 className={styles.subttitle}>Products 
                            <FontAwesomeIcon icon={ isActiveModules ? faChevronUp : faChevronDown} className={styles.chevronArrow} onClick={() => {showModules()}}/>
                        </h3>
                        <ul className={isActiveModules ? styles.show : styles.hide}>
                            <li><Link to="/library"> Library </Link></li>
                            <li><Link to="/studio">Studio</Link></li>
                            <li><Link to="/wizr-connect">WiZR Connect</Link></li>
                            <li><Link to="/wizr-x">WiZR X</Link></li>
                            <li><Link to="/mpublisher">MPublisher</Link></li>
                            <li><Link to="/magazine">Magazine</Link></li>
                            <li><Link to="/wizr-up">WiZR UP</Link></li>
                        </ul>
                    </div>
                    <div className={styles.secondLink}>
                        <h3 className={styles.subttitle}>About us
                            <FontAwesomeIcon icon={ isActiveAbout ? faChevronUp : faChevronDown} className={styles.chevronArrow} onClick={() => {showAbout()}}/>
                        </h3>
                        <ul className={isActiveAbout ? styles.show : styles.hide}>
                            {/* <li><Link to="#">Background</Link></li> */}
                            <li><Link to="/services">Services</Link></li>
                            <li><Link to="/snapshots">Snapshots</Link></li>
                            <li><Link to="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</Link></li>
                            {/* <li><Link to="#">In Norwegian</Link></li> */}
                        </ul>
                    </div>
                    <div className={styles.others}>
                        <h3 className={styles.subttitle}>Take action</h3>
                        <h3><Link to="/wizr-magazine">Explore Magazine</Link></h3>
                        <h3><Link to="/wizr-magazine/#subscribe">Subscribe Magazine</Link></h3>
                        <h3><Link to="/contact">Contact Us</Link></h3>
                        <h3 className={styles.hiringLink}><Link to="/hiring/#positions">Hiring now. See jobs</Link></h3>

                    </div>
                </div>
                <div className={styles.lower}>
                    Copyright© MASTER WiZR. All Rights Reserved.
                </div>
            </div>
        </div>
    )
}
export default Footer;