// extracted by mini-css-extract-plugin
export var chevronArrow = "footer-module--chevronArrow--68020";
export var container = "footer-module--container--d983f";
export var description = "footer-module--description--20b50";
export var footer = "footer-module--footer--fb637";
export var hide = "footer-module--hide--2da89";
export var hiringLink = "footer-module--hiringLink--90ea5";
export var links = "footer-module--links--a7b87";
export var logo = "footer-module--logo--e58e6";
export var lower = "footer-module--lower--0e857";
export var meet_us = "footer-module--meet_us--2c259";
export var others = "footer-module--others--f6d6e";
export var secondLink = "footer-module--secondLink--e9a66";
export var show = "footer-module--show--c773c";
export var subttitle = "footer-module--subttitle--4c00c";
export var upper = "footer-module--upper--8deff";