import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import ArrowRight from '../../../images/icon-arrow-right.svg';
import ArrowLeft from '../../../images/icon-arrow-left.svg';
import { modulesData } from '../../../constants/modulesData';
import * as styles from './Uses.module.scss';

export default function ModulesContainer({ module, location, ...rest }) {
  const [index, setCurrentIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState(null); // Track the previous index
  const selectedIndex = modulesData[index];



  useEffect(() => {
    // Function to pause the video at the given index
    const pauseVideoAtIndex = (idx) => {
      const videoElement = document.getElementById(`video-${idx}`);
      if (videoElement) {
        videoElement.pause();
      }
    };

    // If there's a previous index and it's not the current index, pause its video
    if (prevIndex !== null && prevIndex !== index) {
      pauseVideoAtIndex(prevIndex);
    }

    // Update the previous index after handling the current change
    setPrevIndex(index);
  }, [index, prevIndex]);

  const handleNext = () => {
    const nextSlide = (index + 1) % modulesData.length;
    setCurrentIndex(nextSlide);
  };

  const handlePrevious = () => {
    const prevSlide = (index - 1 + modulesData.length) % modulesData.length;
    setCurrentIndex(prevSlide);
  };

  const handleButtonClick = (targetIndex) => {
    setCurrentIndex(targetIndex);
  };

  return (
    <div style={{ background: selectedIndex?.background }} className={styles.container}>
      <button onClick={handlePrevious} className={`${styles.arrow} ${styles.leftarrow}`}>
        <ArrowLeft />
      </button>
      <Carousel
        slide={false}
        animation='fade'
        keyboard
        interval={null}
        controls={false}
        indicators={false}
        activeIndex={index}
        className={styles.slides}
      >
        {modulesData.map((module, i) => (
          <Carousel.Item className={styles.slide} key={i}>
            <div className={styles.topPart}>
              <div className={styles.slideContent}>
                <h1>BEST-PRACTICES<br /></h1>
              </div>
              <div className={styles.tabs}>
                {modulesData.map((tab, tabIndex) => (
                  <button
                    key={tabIndex}
                    className={index === tabIndex ? styles.activeTab : styles.tab}
                    onClick={() => handleButtonClick(tabIndex)}
                  >
                    {tab.module}
                  </button>
                ))}
              </div>
              </div>
            <div className={styles.bottomPart}>
              <div className={styles.vdContainer}>
                <video id={`video-${i}`}  loop src={module?.video} controls />
              </div>
              <span>{module?.description}</span>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
      <button onClick={handleNext} className={`${styles.arrow} ${styles.rightarrow}`}>
        <ArrowRight />
      </button>
    </div>
  );
}